import Control from 'ol/control/Control.js'
import locale from '../lang/hr.json'
import { transformExtent } from 'ol/proj'
import { IMAGIS } from '../index.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Style,Text,Fill,Stroke} from 'ol/style.js'

export class Search extends Control {
    constructor(options = {}) {
        const element = document.createElement('div')
        element.innerHTML = `
        <div class="input-group my-2">
            <input class="form-control" placeholder="${locale.searchPlaceHolder}...">
             <button class="btn btn-sm btn-light border-top border-bottom clear">
                <i class="fa-regular fa-times"></i>
            </button>
            <button class="btn btn-sm btn-primary search">
                <i class="fa-regular fa-magnifying-glass fa-lg"></i>
            </button>
        </div>
        <div class="list my-2"></div>
        `
        super({
            target: options.target,
            element
        })
        const input = element.querySelector('input')
        const searchBtn = element.querySelector('.search')
        const clearBtn = element.querySelector('.clear')
        const list = element.querySelector('.list')
        searchBtn.addEventListener('click', () => this.handler(input.value, list))
        input.addEventListener('change', () => this.handler(input.value, list))
        clearBtn.addEventListener('click', () => this.clearSearch(input, list))
        const names = Object.keys(locale).filter(key => key.endsWith("_name") || key =='point_code')
        console.log('names',names)
        // measure point name added
        this.propertyNames = options.propertyNames || names;
        this.maxResults = options.maxResults || 100; // Default max results to 100 if not provided
        this.select = options.select
        this.textStyle = (cnt) => new Style({
            text: new Text({
                font: '12px Calibri,sans-serif',
                text: cnt,  // The text to display
                fill: new Fill({
                    color: '#000'  // Text color
                }),
                stroke: new Stroke({
                    color: '#fff',  // Outline color
                    width: 3  // Outline width
                })
            })
        });
        this.maxResultsPerProperty = options.maxResultsPerProperty || 8;
    }

    setMap(map) {
        super.setMap(map)
        this.map = map
    }
    handler(value, list) {
        console.log('value and list:',value, list)
        list.replaceChildren()
        const proj = this.map.getView().getProjection()
        // Search vector layer features
        this.searchVectorLayerFeatures(value, list);
        // Search using Nominatim
        fetch(this.getURL(value, IMAGIS.properties.bbox))
            .then(r => r.json()
                .then(r => {
                    r.features.forEach(x => {
                        const cnt = x.properties.display_name
                        const geom = transformExtent(x.bbox, 'EPSG:4326', proj)
                        this.addItem(list, cnt, geom)
                    })
                })
                .catch(err => console.log(err))
            )
    }

    getURL(value, viewbox) {
        const url = new URL('https://nominatim.openstreetmap.org/search.php')
        url.searchParams.append('q', value)
        url.searchParams.append('polygon_geojson', 1)
        url.searchParams.append('format', 'geojson')
        url.searchParams.append('countrycodes', locale['ISO_3166-1_alpha-2_country_code'])
        if (viewbox) {
            url.searchParams.append('viewbox', viewbox)
            url.searchParams.append('bounded', 1)
        }
        return url
    }

    searchVectorLayerFeatures(value, list) {
        const propertyResultCount = {}; // Praćenje broja rezultata po svojstvu
        let totalResults = 0;
    
        this.map.getAllLayers().forEach(layer => {
            if (layer instanceof VectorLayer && totalResults < this.maxResults) {
                const features = layer.getSource().getFeatures();
                features.forEach(feature => {
                    if (totalResults >= this.maxResults) return;
                    const properties = feature.getProperties();
                    Object.keys(properties).forEach(prop => {
                        const propValue = properties[prop];
                        if (
                            (typeof propValue === 'string' || typeof propValue === 'number') &&
                            String(propValue).toLowerCase().includes(value.toLowerCase())
                        ) {
                            if (!propertyResultCount[prop]) {
                                propertyResultCount[prop] = 0;
                            }
                            if (propertyResultCount[prop] < this.maxResultsPerProperty) {
                                const featureName = `${locale[layer.get('name')]}, ${locale[String(prop)]}: ${propValue}`;
                                const geometry = feature.getGeometry().getExtent();
                                this.addItem(list, featureName, geometry);
                                propertyResultCount[prop]++;
                                totalResults++;
                            }
                        }
                    });
                });
            }
        });
    }
    

    addItem(list, cnt, geom) {
        const item = document.createElement('div')
        item.className = 'card mb-1 me-1 p-1'
        item.innerHTML = `<div class="card-text text-break">${cnt}</div>`
        item.addEventListener('click', () => {
            list.querySelectorAll('.bg-primary').forEach(l => l.classList.remove('bg-primary', 'text-white'))
            item.classList.add('bg-primary', 'text-white')
            this.map.getView().fit(geom, { nearest: true, maxZoom: 21 })
            if (this.layer) {
                this.layer.setMap(null);  // Detach the layer from the map
                this.layer = null;  // Clear the reference to the layer
            }
            this.layer = new VectorLayer({
                source: new VectorSource({
                    features: [
                        new Feature({
                            geometry: new Point(
                                this.map.getView().getCenter()  // Assuming this.map is defined and has a valid view
                            )
                        })
                    ]
                }),
                style:this.textStyle(cnt)
            }); 
            this.layer.setMap(this.map)
        })
        list.append(item)
    }

    clearSearch(input, list) {
        if (this.layer) {
            this.layer.setMap(null);  // Detach the layer from the map
            this.layer = null;  // Clear the reference to the layer
        }
        input.value = ''; // Clear the input field
        list.replaceChildren(); // Clear the search results
    }
}